.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;  /* You can adjust the background as needed */
  }
  
  .contact-form {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    padding: 20px;
  }
  
  .contact-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  
  .form-content {
    flex: 2;
    padding: 20px;
  }
  
  .form-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group input,
  .input-group textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s ease;
  }
  
  .input-group input:focus,
  .input-group textarea:focus {
    border-color: #7a57d1; /* Adjust the color for a modern feel */
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  button {
    width: 100%;
    padding: 15px;
    border: none;
    background-color: #7a57d1;  /* Primary button color */
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #6748b2;  /* Slightly darker shade on hover */
  }
  