.home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .logo-container {
    text-align: center;
  }
  
  .home-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
  }
  