.privacy-policy {
    padding: 20px;
    max-width: 800px;
    margin: 50px auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;  /* Aligns the text to the left */
  }
  
  .privacy-policy h2, 
  .privacy-policy h3, 
  .privacy-policy p, 
  .privacy-policy ul {
    text-align: left;  /* Ensures all elements are aligned to the left */
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  