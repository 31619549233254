.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #68898c;
  color: white;
  align-items: center;
}

.header .logo {
  display: flex;
  align-items: center;
}

.header-logo {
  width: 100px;  /* Adjust the size as needed */
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.header nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}
